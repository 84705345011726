import React from 'react';
import "./style.scss";

const SvgIcon = (props) => {
  if (props.pdfViewMode) {
    return <svg aria-hidden="true"
                style={{width: '100%',height: '100%'}}
                className={`svg-icon ${props.href} ${props.className || ''}`}
                onClick={props.onClick}>
      <use xlinkHref={`#${props.href}`}/>
    </svg>
  } else {
    return <svg aria-hidden="true"
                className={`svg-icon ${props.href} ${props.className || ''}`}
                onClick={props.onClick}>
      <use xlinkHref={`#${props.href}`}/>
    </svg>
  }
};

export default SvgIcon;

