import React from 'react';
import {connect} from 'dva';
import {withRouter} from 'dva/router';
import {ConfigProvider} from 'antd';
import routers from './../router-config';
import pathToRegexp from 'path-to-regexp'
import classNames from 'classnames'
import UserMenu from "../components/user-menu";
import esEs from 'antd/es/locale/es_ES'
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
// import enUS from 'antd/es/locale/en_US';

// 多语言
const messages = {
    // 'en': enUS,
    'zh': zhCN,
    'es': esEs,
    'en': enUS

};

const PageLayout = ({appModel, children, dispatch, location: {pathname}, ...props}) => {
    // 获取页面设置
    const page = (routers || []).find(router => pathToRegexp(router.path).exec(pathname)) || {};
    const cls = classNames('page-body', {'page-max': page.layoutMax});
    let {lang = 'zh'} = appModel;
    document.body.scrollTop = 0;
    // 获取页面设置
    return (
        <ConfigProvider locale={messages[lang]}>
            <div className={cls}>
                {
                    page.showMenu &&
                    <UserMenu pathname={pathname} history={props.history} dispatch={dispatch}/>
                }
                <div className="page-main">{children}</div>
            </div>
        </ConfigProvider>
    );
};

const mapStateToProps = (state) => {
    return {...state}
};
export default withRouter(connect(mapStateToProps)(PageLayout));
