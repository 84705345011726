const UA = localStorage.getItem('wkData-webUA') || window.navigator.userAgent;


const CheckUtil = {
    isPhone(v) {
        return /^1([3456789])\d{9}$/.test(v);
    },
    isTelephone(v) {
        return /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(v);
    },

    isPassword(v) {
        return /^([a-zA-Z0-9]|[@._]){6,20}$/.test(v)
    },
    isUrl(v) {
        //return /^((ht|f)tps?):\/\/[\w]+(\.[\w]+)+([\w\-,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/.test(v);
        return /^((ht|f)tps?):\/\/[\w]+(\.[\w]+)+([\w\-,@?^=%&:~#]*[\w\-?^=%&~#])?$/.test(v);

    },
    //校验邮箱格式
    isEmail: (v) => {
        // return /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(v)
        return /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(v)
    },
    //是否数字
    isNumber: function (value) {
        return !isNaN(value);
    },
    isMobile: UA.match(/(iPhone|iPod|Android|ios)/i),
    isSafari: (UA.indexOf("Safari") > -1 && UA.indexOf("Chrome") < 0),
    isWeChat: (UA.toLowerCase().match(/MicroMessenger/i) === "micromessenger"),
    //是否小程序
    isMiniProgram: window.__wxjs_environment === 'miniprogram',
    isTwoDecNumber(v) {
        return /^\d{0,8}\.{0,1}(\d{1,2})?$/.test(v);
    },
    hasEmoji(v) {
        return /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83C[\uDC00-\uDFFF]/g.test(v);
    },

    isExperience: (companyId) => {
        const experienceCompanyIds = [12];
        return !!experienceCompanyIds.find(item => item === companyId);
    },
    //是否数组
    isArray: function (obj) {
        return Array.isArray(obj);
    },
    //是否空对象
    isEmptyObject: function (obj) {
        return typeof obj === 'object' && Object.keys(obj).length === 0;
    },
    //判断两个对象是否相等
    isEqualObj: function (a, b) {
        if (typeof a !== 'object' || typeof b !== 'object') {
            return false;
        }
        // Of course, we can do it use for in
        // Create arrays of property names
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);
        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }
        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];
            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        // If we made it this far, objects
        // are considered equivalent
        return true;
    }
};

export default CheckUtil;
