import {message} from 'antd'

const hostname = window.location.hostname;
const prodHost = 'ecgo';
const testHost = 'yimaideng';
const devHost = `http://${hostname}`;
const isCustomHost = true;
const UrlUtil = {
    getHost(){
        if (process.env.API_ENV === 'production') {
            return prodHost;
        } else if (process.env.API_ENV === 'test') {
            return testHost;
        } else {
            return devHost;
        }
    },
    getAPI() {
        if (process.env.API_ENV === 'production') {
            return `https://api.${prodHost}.cc`;
        } else if (process.env.API_ENV === 'test') {
            return `http://api.yimaideng.cn`;
        } else {
            return `${devHost}:8990`;
            // return `http://api.ecgo.cc`;
            // return `http://192.168.0.124:7002`;
            // return `http://api.yimaideng.cn`;
            // return `http://api.qipeidd.cc`;
        }
    },
    getAccountUrl: (ccpanyID) => {
        if (isCustomHost) {
            return window.location.origin;
        } else if (process.env.API_ENV === 'production') {
            return ccpanyID ? `https://store${ccpanyID}.${prodHost}.cc` : `https://account.${prodHost}.cc`
        } else if (process.env.API_ENV === 'test') {
            return ccpanyID ? `http://store${ccpanyID}.${testHost}.cn` : `http://account.${testHost}.cn`
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:8993`;
        }
    },
    getPCShopUrl: (ccpanyID) => {
        if (!ccpanyID) return message.info('缺少企业id');
        if (isCustomHost) {
            return window.location.origin;
        } else if (process.env.API_ENV === 'production') {
            return `https://store${ccpanyID}.${prodHost}.cc`
        } else if (process.env.API_ENV === 'test') {
            return `http://store${ccpanyID}.${testHost}.cn`
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:8997`;
        }
    },
    getPCMgrUrl: (ccpanyID) => {
        if (!ccpanyID) return message.info('缺少企业id');
        if (isCustomHost) {
            return window.location.origin;
        } else if (process.env.API_ENV === 'production') {
            return `https://store${ccpanyID}.${prodHost}.cc`
        } else if (process.env.API_ENV === 'test') {
            return `http://store${ccpanyID}.${testHost}.cn`
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:8990`;
        }
    },
    getMShopUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://m.${prodHost}.cc`
        } else if (process.env.API_ENV === 'test') {
            return `http://m.${testHost}.cn`
        } else {
            return `${devHost}:8991`;
        }
    },

    getMMgrUrl() {
        if (process.env.API_ENV === 'production') {
            // return `http://mtest.qipeidd.cn`;
            return `http://mgr.${prodHost}.cc`;
        } else if (process.env.API_ENV === 'test') {
            return `http://mgr.${testHost}.cn`;
        } else if (process.env.API_ENV === 'development') {
            return `${devHost}:8996`;
        }
    },
    getLightUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://pd.${prodHost}.cc`
        } else if (process.env.API_ENV === 'test') {
            return `http://pd.${testHost}.cn`
        } else {
            return `${devHost}:8999`;
        }
    },
    getBitMapUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://point.${prodHost}.cc`
        } else if (process.env.API_ENV === 'test') {
            return `http://point.${testHost}.cn`
        } else {
            return `${devHost}:5003`;
        }
    },
};
export default UrlUtil;
